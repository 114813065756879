import * as React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Layout } from '../../components/layout'
import { H1, Paragraph } from '../../components/typography'
import { RichText } from '../../components/content'

const Post = ({ data }) => {
    const post = data.post

    return (
        <Layout seo={post?.seo}>
            <HeroWrapper>
                <ImageWrapper image={post?.featuredImage.asset.gatsbyImageData} alt={post?.featuredImage.alt} />
                <H1 textAlign='center' margin='var(--space-04) 0'>{post?.title ?? `Post Title`}</H1>
                <Paragraph margin='0' padding='0 var(--space-03)' textAlign='center'>Published on {post?.date} by {post?.author.name}</Paragraph>
            </HeroWrapper>
            <RichText blocks={post?._rawContent.content} />
        </Layout>
    )
}

export default Post

export const query = graphql`
    query PostQuery($id: String!) {
        post: sanityPost(id: { eq: $id }) {
            _key
            title
            slug {
                current
            }
            author {
                name
                image {
                    alt
                    asset {
                        gatsbyImageData(
                            width: 250,
                            height: 250,
                            aspectRatio: 1,
                        )
                    }
                }
            }
            date(formatString: "MMMM Do YYYY")
            featuredImage {
                alt
                asset {
                    gatsbyImageData(
                        width: 1440,
                        height: 700,
                    )
                }
            }
            _rawContent
        }
    }
`

const HeroWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space-06) 0;
`

const ImageWrapper = styled(GatsbyImage)`
    max-width: 1440px;
`

